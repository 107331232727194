.header {
  height: 100%;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 39px;
  @include media("<=1024px") {
    padding: 34px 20px 0;
  }
  @include media("<=tablet") {
    padding: 34px 50px;
  }

  @include media("<=420px") {
    padding: 24px 70px 8px 12px;
  }
}

.header-logo img {
  @include media("<=420px") {
    max-width: 200px;
  }
}

.header-nav {
  @include media("<=tablet") {
    display: none;

    &.active {
      display: flex;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: #fff;
      z-index: 20;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: space-between;
      align-items: center;
      opacity: 0.9;

      .header-link {
        padding: 20px;
        margin-left: 0;
        font-size: 20px;
      }
    }
  }
}

.header-link {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  color: #443074;
  line-height: 22.69px;
  margin-left: 45px;
  transition: color 0.3s linear;
}

.header-link:hover {
  color: #e6007e;
}
