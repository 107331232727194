.about {
  padding-top: 170px;
  padding-bottom: 41px;
  background-image: url(../images/about-bg.png);
  background-position: center;
  background-size: cover;
  @include media("<=1024px") {
    padding-top: 150px;
  }
  @include media("<=tablet") {
    padding-top: 100px;
  }
  @include media("<=420px") {
    padding-bottom: 10px;
  }
}

.about-inner {
  display: flex;
  position: relative;
  top: -60px;
  @include media("<=1024px") {
    padding: 0 30px;
  }
  @include media("<=tablet") {
    top: -20px;
  }
  @include media("<=420px") {
    top: 0;
    flex-direction: column-reverse;
    padding: 30px 30px 0;
  }
}

.about-text {
  width: 100%;
  max-width: 630px;
  flex: 0 0 50%;
  margin-right: 63px;
  padding-top: 100px;
  @include media("<=tablet") {
    margin-right: 0;
    padding-bottom: 0;
    padding-top: 60px;
  }
  @include media("<=420px") {
    padding-top: 0;
  }
  p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 35px;
    margin-bottom: 35px;
    @include media("<=1024px") {
      line-height: 30px;
    }
    @include media("<=tablet") {
      line-height: 20px;
      font-size: 18px;
    }
    @include media("<=420px") {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 28px;
    }
  }
}

.about-img {
  position: relative;
  flex: 0 0 50%;
  max-width: 545px;
  img {
    width: 100%;
  }
}

.about-pic {
  position: absolute;
  &.pic1 {
    top: -15%;
    left: -15%;
    @include media("<=1280px") {
      bottom: -15%;
    }
    @include media("<=420px") {
      display: none;
    }
  }
  &.pic2 {
    bottom: -30%;
    left: 15%;
    @include media("<=1280px") {
      bottom: -35%;
    }

    @include media("<=420px") {
      width: 60px;
      bottom: -10%;
    }
  }
}
