.partners {
  padding-top: 573px;
  padding-bottom: 402px;
  background-color: rgba(83, 171, 209, 0.8);
  background-image: url(../images/partners-bg.png), url(../images/partners.png);
  background-position: center top, center;
  background-size: cover, cover;
  position: relative;
  @include media("<=tablet") {
    padding-top: 800px;
  }
  @include media("<=420px") {
    padding-top: 680px;
    padding-bottom: 280px;
  }
  @include media("<=350px") {
    padding-top: 700px;
  }
}

.partners-inner {
  padding-top: 87px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @include media("<=1024px") {
    padding: 70px 20px 0;
  }
  @include media("<=tablet") {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  @include media("<=420px") {
    padding-top: 50px;
  }
}

.partners-item {
  flex: 0 1 15%;
  max-width: 170px;
  max-height: 49px;
  height: 100%;
  cursor: pointer;
  @include media("<=tablet") {
    flex: 0 1 30%;
    max-width: 190px;
    margin-bottom: 40px;
  }
}

.partners-img-1 {
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100%;
}

.partners-img-2 {
  display: none;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.partners-item:hover {
  .partners-img-1 {
    opacity: 0;
    visibility: hidden;
    display: none;
  }

  .partners-img-2 {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
.partners-pic {
  position: absolute;
  &.pic1 {
    top: -30%;
    right: -15%;
    @include media("<=1440px") {
      right: -8%;
    }
    @include media("<=1280px") {
      top: -50%;
      right: 0;
    }
    @include media("<=420px") {
      display: none;
    }
  }
  &.pic2 {
    bottom: -160%;
    left: -15%;
    @include media("<=1280px") {
      bottom: -180%;
      left: -5%;
    }
    @include media("<=420px") {
      display: none;
    }
  }
  &.pic3 {
    bottom: -350%;
    right: 10%;
    @include media("<=1280px") {
      bottom: -280%;
    }
    @include media("<=420px") {
      display: none;
    }
  }
}

.parallax-scene-bottom,
.parallax-scene-top {
  position: absolute;
  transition: transform 0.25ms ease-in-out;
}
