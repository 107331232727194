.steps {
  padding-top: 98px;
  padding-bottom: 105px;
  position: absolute;
  top: -85px;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  max-width: 1456px;
  height: 544px;
  background: linear-gradient(#53abd1, #438cc1);
  @include media("<=1440px") {
    width: 90%;
  }
  @include media("<=tablet") {
    padding-top: 70px;
    padding-bottom: 50px;
    height: auto;
  }
  @include media("<=420px") {
    padding: 40px 30px;
  }
}

.steps-inner {
  padding: 60px 30px 0;
  display: flex;
  justify-content: space-between;
  @include media("<=tablet") {
    flex-direction: column;
    padding: 40px 40px 0;
    align-items: center;
  }
  @include media("<=420px") {
    padding: 30px 0 0;
  }
}

.steps-item {
  display: flex;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 165px;
  @include media("<=tablet") {
    flex-direction: row;
    text-align: left;
    max-width: 450px;
  }

  &::before {
    content: "";
    display: inline-block;
    background-image: url(../images/fast-forward.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 150px;
    height: 110px;
    position: absolute;
    left: -110px;
    top: 20px;
    opacity: 0.4;
    @include media("<=1024px") {
      left: -70px;
    }
    @include media("<=tablet") {
      display: none;
    }
  }
  &:first-of-type::before {
    display: none;
  }
}

.steps-num {
  background: linear-gradient(to top, #e2569c 0%, #e2569c 37%, #6e12b9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #531487;
  filter: drop-shadow(6px 7px 0 #ffffff);
  font-family: "Advantage-Demi";
  font-size: 139px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
  @include media("<=tablet") {
    margin-bottom: 0;
    font-size: 120px;
    margin-right: 20px;
  }
  @include media("<=420px") {
    font-size: 100px;
  }
}

.steps-text {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  @include media("<=tablet") {
    text-align: left;
    font-size: 26px;
  }
  @include media("<=420px") {
    font-size: 22px;
  }
}

hr {
  display: none;
  height: 3px;
  color: #fff;
  background-color: #fff;
  opacity: 0.2;
  text-align: center;
  width: 85%;
  margin: 0 auto;
  border: 0;
  @include media("<=tablet") {
    display: block;
  }
}
