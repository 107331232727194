.hero {
  background-image: url(../images/hero-bg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 81px;
  @include media("<=1024px") {
    padding-top: 100px;
  }
  @include media("<=tablet") {
    padding-top: 80px;
  }
  @include media("<=420px") {
    padding-top: 40px;
  }
}

.hero-inner {
  position: relative;
  padding-bottom: 540px;
  @include media("<=1024px") {
    padding-bottom: 420px;
  }
  @include media("<=tablet") {
    padding-bottom: 310px;
  }
  @include media("<=420px") {
    padding-bottom: 200px;
  }
}

.hero-title {
  color: #ffffff;
  font-family: "Roboto";
  font-size: 74px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  @include media("<=1024px") {
    font-size: 70px;
  }
  @include media("<=tablet") {
    font-size: 64px;
  }
  @include media("<=420px") {
    font-size: 42px;
  }
  @include media("<=375px") {
    font-size: 34px;
  }
}

.hero-img {
  position: absolute;
  top: 185px;
  left: 50%;
  transform: translate(-53%, 0);
  img {
    @include media("<=1024px") {
      width: 100%;
    }
  }
  @include media("<=1024px") {
    width: 100%;
    max-width: 1024px;
    top: 160px;
  }
  @include media("<=420px") {
    width: 115%;
    top: 130px;
  }
}

.hero-pic {
  position: absolute;
  &.pic1 {
    bottom: 45%;
    left: -10%;
    @include media("<=1280px") {
      left: 0;
    }
    @include media("<=420px") {
      display: none;
    }
  }
  &.pic2 {
    bottom: 35%;
    right: 0;
    @include media("<=420px") {
      width: 24px;
    }
  }
  &.pic3 {
    bottom: -4%;
    right: -12%;
    @include media("<=1440px") {
      right: -10%;
    }
    @include media("<=1280px") {
      display: none;
    }
  }
}
