.why {
  padding: 98px 0 105px;
  background: linear-gradient(#244b89, #53abd1);
  @include media("<=1280px") {
    overflow: hidden;
    padding: 65px 0 70px;
  }
  @include media("<=1024px") {
    padding: 50px 0 60px;
  }
  @include media("<=420px") {
    padding: 30px 0 40px;
  }
}

.why-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 89px;
  position: relative;
  @include media("<=1280px") {
    padding-top: 60px;
  }
  @include media("<=1024px") {
    padding: 50px 20px 0;
  }
  @include media("<=tablet") {
    padding: 40px 30px 0;
    justify-content: space-around;
  }
  @include media("<=640px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px 0;
  }
}

.why-item {
  flex: 0 1 33%;
  height: 361px;
  max-width: 392px;
  margin-bottom: 12px;
  border-radius: 5px;
  padding: 40px 30px 60px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  box-shadow: 0 10px 40px 0px rgba(0, 0, 0, 0.5);
  @include media("<=1024px") {
    max-width: 320px;
    height: auto;
    padding: 20px 10px 40px;
  }
  @include media("<=tablet") {
    max-width: 340px;
    flex: 0 1 50%;
    padding: 20px 15px 40px;
  }
  @include media("<=420px") {
    max-width: 360px;
    flex: 0 1 100%;
    flex-direction: row;
    padding-bottom: 30px;
  }
}

.why-icon {
  margin-bottom: 25px;
  @include media("<=420px") {
    margin-bottom: 0;
    width: 100%;
    max-width: 80px;
  }
  img {
    @include media("<=420px") {
      width: 80%;
    }
  }
}

.why-title {
  color: #4b4b4b;
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  @include media("<=1024px") {
    font-size: 24px;
  }
  @include media("<=420px") {
    font-size: 20px;
    margin-bottom: 12px;
    text-align: left;
  }
}

.why-text {
  color: #858585;
  font-family: Roboto;
  font-size: 16px;
  text-align: center;
  @include media("<=1024px") {
    font-size: 15px;
  }
  @include media("<=420px") {
    font-size: 14px;
    text-align: left;
  }
}

.why-pic {
  position: absolute;
  &.pic1 {
    right: -12%;
    top: -10%;
    @include media("<=1440px") {
      right: -10%;
    }
    @include media("<=420px") {
      display: none;
    }
  }
  &.pic2 {
    top: 55%;
    left: -15%;
  }
  @include media("<=420px") {
    display: none;
  }

  &.pic3 {
    top: 65%;
    right: -17%;
    @include media("<=1440px") {
      left: -10%;
    }
    @include media("<=tablet") {
      display: none;
    }
    @include media("<=420px") {
      display: none;
    }
  }
}
