.games {
  background-image: url(../images/games-bg.png);
  background-position: center;
  background-size: cover;
  padding-top: 105px;
  padding-bottom: 191px;
  @include media("<=tablet") {
    padding-top: 80px;
    padding-bottom: 160px;
  }
  @include media("<=420px") {
    padding-top: 50px;
    padding-bottom: 120px;
  }
}

.games-inner {
  display: flex;
  width: 100%;
  max-width: 927px;
  padding-top: 95px;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
  @include media("<=tablet") {
    flex-direction: column;
    padding: 60px 20px 0;
  }
  @include media("<=420px") {
    padding: 30px 20px 0;
  }
}

.tab {
  width: 100%;
  max-width: 124px;
  display: flex;
  flex-direction: column;
  @include media("<=tablet") {
    flex-direction: row;
    max-width: none;
    justify-content: space-around;
    align-items: center;
    padding: 0 55px;
    top: 20px;
    position: relative;
  }
  @include media("<=420px") {
    padding: 0 20px;
  }
  img {
    width: 100%;
    display: block;
  }
}

.tablinks {
  display: block;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  background-color: inherit;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s linear;
  margin-bottom: 20px;
  @include media("<=tablet") {
    max-width: 124px;
    margin-bottom: 0;
    width: 100%;
  }
  @include media("<=420px") {
    max-width: 80px;
  }
  @include media("<=350px") {
    max-width: 70px;
  }
  &:hover,
  &.active {
    box-shadow: 0 0 21px 4px #e6007e;
  }
}

.tab-block {
  padding: 75px 80px 65px 75px;
  width: 100%;
  max-width: 775px;
  max-height: 416px;
  border-radius: 10px;
  background-color: #ffffff;
  @include media("<=tablet") {
    padding: 60px 70px;
    max-height: none;
  }
  @include media("<=420px") {
    padding: 40px 20px;
  }
  @include media("<=375px") {
    padding: 40px 15px;
  }
}

.tabcontent {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  animation: slide-down 0.3s ease-out;
  &:first-of-type {
    display: flex;
  }
  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(10%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  h2 {
    padding-left: 15px;
    color: #4b4b4b;
    font-size: 33px;
    font-weight: 700;
    @include media("<=420px") {
      font-size: 24px;
      padding-left: 0;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  p {
    padding-left: 15px;
    color: #858585;
    line-height: 30px;
    font-size: 18px;
    @include media("<=420px") {
      font-size: 14px;
      padding-left: 0;
      text-align: center;
      line-height: 16px;
      margin-bottom: 10px;
    }
  }
}

.tab-stats {
  display: flex;
  justify-content: space-between;
}

.stats-item {
  width: 100%;
  max-width: 200px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  text-align: center;
  background-color: #ff5ea0;
  padding: 15px 0;
  @include media("<=tablet") {
    max-width: 180px;
  }
  @include media("<=420px") {
    max-width: 100px;
    padding: 10px 0;
    height: auto;
  }
  @include media("<=375px") {
    max-width: 90px;
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    @include media("<=420px") {
      font-size: 18px;
    }
  }
  p {
    padding-left: 0;
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    @include media("<=tablet") {
      line-height: 14px;
    }
    @include media("<=420px") {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.games-pic {
  position: absolute;
  &.pic1 {
    top: -40%;
    left: -10%;
    @include media("<=1280px") {
      top: -25%;
      left: 10%;
    }
    @include media("<=420px") {
      width: 50px;
      left: 4%;
    }
  }
  &.pic2 {
    top: -10%;
    right: -30%;
    @include media("<=1440px") {
      right: -14%;
    }
    @include media("<=1024px") {
      display: none;
    }
  }
  &.pic3 {
    bottom: 0;
    left: -30%;
    @include media("<=1280px") {
      left: -20%;
    }
    @include media("<=420px") {
      width: 70px;
    }
  }
}
