.contacts {
  position: absolute;
  max-width: 646px;
  width: 100%;
  height: 405px;
  padding: 62px 0 53px;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #ffffff;
  bottom: -100px;
  left: 50%;
  transform: translate(-50%, 0);
  @include media("<=420px") {
    width: 85%;
    padding: 40px 0;
    height: auto;
  }
  .section-title {
    color: #53abd1;
  }
}

.contacts-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 35px;
}

.contacts-img {
  margin-bottom: 32px;
  @include media("<=420px") {
    margin-bottom: 30px;
    width: 100%;
    max-width: 120px;
  }
  img {
    @include media("<=420px") {
      width: 100%;
    }
  }
}

.contacts-email {
  color: #53abd1;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
