.footer {
  background-color: #000;
}

.footer-inner {
  padding: 150px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include media("<=420px") {
    padding: 130px 0 30px;
  }
}

.footer-logo {
  margin-bottom: 20px;
  @include media("<=420px") {
    width: 100%;
    max-width: 150px;
  }
  img {
    width: 100%;
  }
}

.footer-text {
  color: #929292;
  font-size: 14px;
}
