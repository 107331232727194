@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.eot");
  src: local("☺"), url("../fonts/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto-Regular.ttf") format("truetype"),
    url("../fonts/Roboto-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Black.eot");
  src: local("☺"), url("../fonts/Roboto-Black.woff") format("woff"),
    url("../fonts/Roboto-Black.ttf") format("truetype"),
    url("../fonts/Roboto-Black.svg") format("svg");
  font-weight: 900;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

@font-face {
  font-family: "Advantage-Demi";
  src: url("../fonts/Advantage Demi.eot");
  src: local("☺"), url("../fonts/Advantage Demi.woff") format("woff"),
    url("../fonts/Advantage Demi.ttf") format("truetype"),
    url("../fonts/Advantage Demi.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
